// extracted by mini-css-extract-plugin
export var addressBtn = "style-module--address-btn--3992a";
export var addressImage = "style-module--address-image--52090";
export var addressTitle = "style-module--address-title--75bf4";
export var addressValue = "style-module--address-value--66d0b";
export var big = "style-module--big--256e5";
export var containerAddress = "style-module--container-address--da690";
export var containerBottom = "style-module--container-bottom--747a9";
export var containerRequisites = "style-module--container-requisites--4a15a";
export var containerTopList = "style-module--container-top-list--ec66f";
export var description = "style-module--description--a62df";
export var imageCoin = "style-module--image-coin--14182";
export var imageLine1 = "style-module--image-line1--1df3b";
export var imageLine2 = "style-module--image-line2--dfe7a";
export var link = "style-module--link--cbda5";
export var requisitesImage = "style-module--requisites-image--e4051";
export var requisitesItem = "style-module--requisites-item--5aad1";
export var requisitesItemTitle = "style-module--requisites-item-title--b0114";
export var requisitesItemValue = "style-module--requisites-item-value--0171f";
export var requisitesItems = "style-module--requisites-items--7462c";
export var requisitesTitle = "style-module--requisites-title--9a37c";
export var small = "style-module--small--53a82";
export var title = "style-module--title--2928e";
export var topItem = "style-module--top-item--4c02b";
export var topItemBtn = "style-module--top-item-btn--18ff4";
export var topItemContent = "style-module--top-item-content--d35d4";
export var topItemDescription = "style-module--top-item-description--841b3";
export var topItemImage = "style-module--top-item-image--4958f";
export var topItemLink = "style-module--top-item-link--7f32f";
export var topItemTitle = "style-module--top-item-title--008bc";
export var topListItems = "style-module--top-list-items--7fb2f";
export var topSection = "style-module--top-section--6a6a9";