import React from "react";
import {Helmet} from "react-helmet";

import {ButtonUI, TypographyUI} from "@ui";
import {
    AdaptiveLinkCt,
    Layout,
    RequestToJoinCt,
    useRequestToJoin,
} from "@components";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import * as styles from "@pages/contacts/style.module.scss";
import {NoGapUI} from "@ui";
import {useSendEvent} from "@tools/hooks";
import {StaticImage} from "gatsby-plugin-image";

declare let carrotquest: any;

const ContactsPage = () => {
    const {supportClick} = useSendEvent();

    const {
        name,
        setName,
        phone,
        setPhone,
        email,
        setEmail,
        btnDisabled,
        handlerOfSubmitForm,
        isRequestSuccess,
        setIsRequestSuccess,
        openModalWindow,
        setOpenModalWindow,
    } = useRequestToJoin();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Контакты</title>
                <meta
                    name="description"
                    content="Контактная информация: чат поддержки, телефон, почта, трудоустройство, адрес, социальные сети"
                />
                <link
                    rel="canonical"
                    href="https://xn--80aapgyievp4gwb.xn--p1ai/contacts"
                />
            </Helmet>
            <Layout>
                <div className={styles.topSection}>
                <TypographyUI.H1
                    swichPoint={LAPTOP_MOBILE_POINTER}
                    className={styles.title}
                >
                    Будем на связи!
                </TypographyUI.H1>

                <TypographyUI.Body1Regular
                    swichPoint={LAPTOP_MOBILE_POINTER}
                    className={styles.description}
                >
                    Вы можете задать вопрос по телефону{" "}
                    <AdaptiveLinkCt to="tel:+7 (495) 088 99 88">
                        <NoGapUI>+7 (495) 088 99 88</NoGapUI>
                    </AdaptiveLinkCt>{" "}
                    <NoGapUI>с 09:00 до 18:00</NoGapUI>, по электронной почте{" "}
                    <AdaptiveLinkCt to="mailto:support@selfwork.ru" className={styles.link}>
                        support@selfwork.ru
                    </AdaptiveLinkCt>{" "}
                    или в нашем{" "}
                    <AdaptiveLinkCt to="https://t.me/selfworkru" className={styles.link}>
                        телеграм-канале
                    </AdaptiveLinkCt>
                </TypographyUI.Body1Regular>

                <div className={styles.imageCoin}>
                    <StaticImage
                        src="../../images/contacts/coin.png"
                        loading="eager"
                        placeholder="none"
                        formats={["auto", "webp"]}
                        layout="fixed"
                        width={63}
                        height={41}
                        objectFit="contain"
                        alt=""
                    />
                </div>
                <div className={styles.imageLine1}>
                    <StaticImage
                        src="../../images/contacts/line1.png"
                        loading="eager"
                        placeholder="none"
                        formats={["auto", "webp"]}
                        layout="fixed"
                        width={49}
                        height={79}
                        objectFit="contain"
                        alt=""
                    />
                </div>
                <div className={styles.imageLine2}>
                    <StaticImage
                        src="../../images/contacts/line2.png"
                        loading="eager"
                        placeholder="none"
                        formats={["auto", "webp"]}
                        layout="fixed"
                        width={74}
                        height={54}
                        objectFit="contain"
                        alt=""
                    />
                </div>
                </div>

                <div className={styles.containerTopList}>
                    <div className={styles.topListItems}>

                        <div className={[styles.topItem, styles.small].join(' ')}>

                            <div className={styles.topItemImage}>
                                <StaticImage
                                    src="../../images/contacts/questions.png"
                                    loading="eager"
                                    placeholder="none"
                                    formats={["auto", "webp"]}
                                    layout="constrained"
                                    objectFit="contain"
                                    alt=""
                                />
                            </div>

                            <div className={styles.topItemContent}>
                                <TypographyUI.H4Bold className={styles.topItemTitle}>
                                    Общие вопросы
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.topItemDescription}
                                >
                                    <a href={"mailto:support@selfwork.ru"}>support@selfwork.ru</a>
                                </TypographyUI.Body1Regular>
                                <TypographyUI.Body1Medium
                                    className={styles.topItemLink}
                                    onClick={() => {
                                        if (carrotquest) {
                                            carrotquest.open()
                                        }
                                        supportClick();
                                    }}
                                >
                                    Задать вопрос
                                </TypographyUI.Body1Medium>
                            </div>
                        </div>

                        <div className={[styles.topItem, styles.small].join(' ')}>

                            <div className={styles.topItemImage}>
                                <StaticImage
                                    src="../../images/contacts/connect-business.png"
                                    loading="eager"
                                    placeholder="none"
                                    formats={["auto", "webp"]}
                                    layout="constrained"
                                    objectFit="contain"
                                    alt=""
                                />
                            </div>

                            <div className={styles.topItemContent}>
                                <TypographyUI.H4Bold className={styles.topItemTitle}>
                                    Подключение юр. лиц
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.topItemDescription}
                                >
                                    <a href={"mailto:sales@selfwork.ru"}>sales@selfwork.ru</a>
                                </TypographyUI.Body1Regular>
                                <TypographyUI.Body1Medium
                                    className={styles.topItemLink}
                                    onClick={() => setOpenModalWindow(true)}
                                >
                                    Подключиться
                                </TypographyUI.Body1Medium>
                            </div>
                        </div>

                    </div>

                    <div className={styles.topListItems}>


                        <div className={[styles.topItem, styles.big].join(' ')}>

                            <div className={styles.topItemImage}>
                                <StaticImage
                                    src="../../images/contacts/vacancies.png"
                                    loading="eager"
                                    placeholder="none"
                                    formats={["auto", "webp"]}
                                    layout="constrained"
                                    objectFit="contain"
                                    alt=""
                                />
                            </div>

                            <div className={styles.topItemContent}>
                                <TypographyUI.H4Bold className={styles.topItemTitle}>
                                    Хотите стать частью команды? Присылайте резюме по адресу
                                </TypographyUI.H4Bold>
                                <TypographyUI.Body1Regular
                                    className={styles.topItemDescription}
                                >
                                    <a href={"mailto:hr@selfwork.ru"}>hr@selfwork.ru</a>
                                </TypographyUI.Body1Regular>

                                <a target="_blank" href="https://hh.ru/employer/4152303">
                                    <ButtonUI className={styles.topItemBtn} $w="276px">
                                        Смотреть вакансии
                                    </ButtonUI>
                                </a>

                            </div>
                        </div>

                    </div>

                </div>
                {/*<div className={styles.containerMap} id="map">*/}
                {/*    <iframe*/}
                {/*        src="https://yandex.ru/map-widget/v1/?um=constructor%3A3473b5398e33ec66d8738ea007f922e80dae4f572ddf6ac38b72c8fa781931fc&amp;source=constructor"*/}
                {/*        width="100%"*/}
                {/*        height="100%"*/}
                {/*        frameBorder="0"*/}
                {/*    />*/}
                {/*</div>*/}

                <div className={styles.containerBottom}>

                    <div className={styles.containerAddress}>
                        <div className={styles.addressImage}>
                            <StaticImage
                                src="../../images/contacts/address.png"
                                loading="eager"
                                placeholder="none"
                                formats={["auto", "webp"]}
                                layout="constrained"
                                objectFit="contain"
                                alt=""
                            />
                        </div>
                        <div>
                            <TypographyUI.H3 className={styles.addressTitle}>
                                Адрес
                            </TypographyUI.H3>
                            <TypographyUI.Body1Regular
                                className={styles.addressValue}
                            >
                                109012, город Москва, ул Ильинка, д. 4, помещ. 105, 1-4 ком. 1
                            </TypographyUI.Body1Regular>
                            <a target="_blank"
                               href="https://yandex.ru/map-widget/v1/?um=constructor%3A3473b5398e33ec66d8738ea007f922e80dae4f572ddf6ac38b72c8fa781931fc">
                                <ButtonUI.Secondary className={styles.addressBtn} $w="240px">
                                    Посмотреть на карте
                                </ButtonUI.Secondary>
                            </a>
                        </div>
                    </div>

                    <div className={styles.containerRequisites}>

                        <div className={styles.requisitesImage}>
                            <StaticImage
                                src="../../images/contacts/requisites.png"
                                loading="eager"
                                placeholder="none"
                                formats={["auto", "webp"]}
                                layout="constrained"
                                objectFit="contain"
                                alt=""
                            />
                        </div>

                        <div>
                            <TypographyUI.H3 className={styles.requisitesTitle}>
                                Реквизиты
                            </TypographyUI.H3>
                            <div className={styles.requisitesItems}>
                                <div className={styles.requisitesItem}>
                                    <TypographyUI.Body1Regular
                                        className={styles.requisitesItemTitle}
                                    >
                                        Полное наименование
                                    </TypographyUI.Body1Regular>
                                    <TypographyUI.Body1Regular
                                        className={styles.requisitesItemValue}
                                    >
                                        ООО «Самозанятые»
                                    </TypographyUI.Body1Regular>
                                </div>

                                <div className={styles.requisitesItem}>
                                    <TypographyUI.Body1Regular
                                        className={styles.requisitesItemTitle}
                                    >
                                        ИНН
                                    </TypographyUI.Body1Regular>
                                    <TypographyUI.Body1Regular
                                        className={styles.requisitesItemValue}
                                    >
                                        9731029523
                                    </TypographyUI.Body1Regular>
                                </div>

                                <div className={styles.requisitesItem}>
                                    <TypographyUI.Body1Regular
                                        className={styles.requisitesItemTitle}
                                    >
                                        ОГРН
                                    </TypographyUI.Body1Regular>
                                    <TypographyUI.Body1Regular
                                        className={styles.requisitesItemValue}
                                    >
                                        1197746123497
                                    </TypographyUI.Body1Regular>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <RequestToJoinCt
                name={name}
                setName={setName}
                phone={phone}
                setPhone={setPhone}
                email={email}
                setEmail={setEmail}
                btnDisabled={btnDisabled}
                handlerOfSubmitForm={handlerOfSubmitForm}
                isRequestSuccess={isRequestSuccess}
                setIsRequestSuccess={setIsRequestSuccess}
                openModalWindow={openModalWindow}
                setOpenModalWindow={setOpenModalWindow}
            />
        </>
    );
};

export default ContactsPage;
